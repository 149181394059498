import React from "react";
import Layout from "../../components/layout";
import { Container, H1 } from "../../styles/ui";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import Contact from "../../components/contact";

const Rolunk: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Rólunk"
      description="Ismerj meg minket!"
    >
      <Wrapper>
        <H1>
          <span>Ismerj meg</span> minket
        </H1>
        <p>
          Csapatunk 2020-ban lépett a piacra azzal a céllal, hogy
          minőségorientált szolgáltatásunk révén kis és közepes vállalkozásokat
          támogassunk a fejlődésükben. Törekszünk a legmagasabb szinten
          képviselni a szakmánkat. Hosszútávú együttműködésre törekszünk,
          amelyhez szerintünk az ügyfeleink elégedettségén keresztül vezet az
          út.
        </p>
        <StaticImage
          style={{ width: "100%" }}
          src="../../images/group2.jpg"
          alt="Csapat"
          imgStyle={{ borderRadius: "20px" }}
        />
        <Card>
          <h3>Hitvallásunk „Az érzékek logikája”</h3>
          <p>
            Sokan azt gondolják, a marketing egyenlő a design-nal és a
            kommunikációval, valamint az értékesítés nem más mint alacsony
            árazás. Mi ezzel szemben azt valljuk, hogy a marketing és
            kereskedelem alaptevékenysége ott kezdődik, hogy megismerjük a
            vásárlóink, fogyasztóink, partnereink igényeit és ehhez, a
            termékeink, szolgáltatásaink piacához igazítva dolgozzuk ki a
            marketing- és kereskedelmi stratégiánkat, vagy akár azok egyes
            részterületeit. Ezen területek egyik eleme csak a design, a
            kommunikáció vagy a komparatív árazás. Hisszük, hogy a marketing és
            kereskedelem csak abban az esetben tud jól működni, amennyiben
            precíz adatok elemzésén alapuló döntéseket hozunk.
          </p>
        </Card>
        <TitleContainer>
          <h1>
            <span>Csapatunk</span> tagjai
          </h1>
        </TitleContainer>

        <Profiles>
          <Profile>
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/profiles/richard.jpg"
              alt="Richárd"
              imgStyle={{ borderRadius: "20px" }}
            />
            <h3>RICHÁRD</h3>
            <p>Tulajdonos</p>
          </Profile>
          <Profile>
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/profiles/istvan.jpg"
              alt="István"
              imgStyle={{ borderRadius: "20px" }}
            />
            <h3>ISTVÁN</h3>
            <p>Marketing vezető</p>
          </Profile>
          <Profile>
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/profiles/eszteranna.jpg"
              alt="Eszter Anna"
              imgStyle={{ borderRadius: "20px" }}
            />
            <h3>ESZTER ANNA</h3>
            <p>Fotó</p>
          </Profile>
          <Profile>
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/profiles/evelyn.jpg"
              alt="Evelyn"
              imgStyle={{ borderRadius: "20px" }}
            />
            <h3>EVELYN</h3>
            <p>Webdesigner</p>
          </Profile>
          <Profile>
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/profiles/gabor.jpg"
              alt="Gábor"
              imgStyle={{ borderRadius: "20px" }}
            />
            <h3>GÁBOR</h3>
            <p>Informatikai fejlesztő</p>
          </Profile>
          <Profile>
            <StaticImage
              style={{ width: "100%" }}
              src="../../images/profiles/eszter.jpg"
              alt="Eszter"
              imgStyle={{ borderRadius: "20px" }}
            />
            <h3>ESZTER</h3>
            <p>Videó</p>
          </Profile>
        </Profiles>
      </Wrapper>
      <Contact variant="marketing" />
    </Layout>
  );
};

export default Rolunk;

const Wrapper = styled(Container)`
  padding-top: 160px;

  p:first-of-type {
    margin-bottom: 32px;
  }
`;

const Card = styled.div`
  background: white;
  width: 500px;
  border-radius: 20px;
  padding: 20px 32px;
  box-shadow: var(--shadow);
  margin-left: auto;
  transform: translate(-10vw, -21vw);

  h3 {
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  @media (max-width: 750px) {
    margin-top: 32px;
    transform: translate(0);
    box-shadow: none;
    padding: 0;
    width: 100%;
  }
`;

const TitleContainer = styled(Container)`
  margin-bottom: 96px;
  text-align: center;
`;

const Profiles = styled.div`
  justify-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 32px;
  max-width: 1200px; // 3*320 + 2*32 + something
  margin: auto;
  @media (max-width: 440px) {
    grid-template-columns: 1fr;
  }
`;

const Profile = styled.div`
  width: 300px;
  h3 {
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 16px;
  }
  h3,
  p {
    margin-left: 8px;
  }

  @media (max-width: 440px) {
    width: 100%;
  }
`;
